import React, { useState, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { uniq, without, throttle } from 'lodash-es';
import GoogleMapReact from 'google-map-react';
import { getCenter } from 'geolib';
import { useResponsive } from 'ahooks';
import { InView } from 'react-intersection-observer';

import { useListings } from 'utils';
import Listing from './Listing';
import ListingMarker from './ListingMarker';

function App() {
  const { loading, listings } = useListings();
  const visibleListingsRef = useRef([]);
  const { lg: screenLarge } = useResponsive();
  const [visibleListings, setVisibleListings] = useState([]);
  const [hoveredListing, setHoveredListing] = useState([]);

  const memoizedSetVisibleListings = useCallback(
    throttle(setVisibleListings, 300),
    [setVisibleListings]
  );

  const listingVisibilityHandler = (inView, listing) => {
    if (inView && !visibleListingsRef.current.includes(listing.url)) {
      visibleListingsRef.current = uniq([
        ...visibleListingsRef.current,
        listing.url,
      ]);
    } else if (!inView && visibleListingsRef.current.includes(listing.url)) {
      visibleListingsRef.current = without(
        visibleListingsRef.current,
        listing.url
      );
    }
    memoizedSetVisibleListings(visibleListingsRef.current);
  };

  if (loading) {
    return <div>Laddar</div>;
  }

  const listingsWithLocations = listings.filter(
    (listing) => listing.latitude && listing.longitude
  );

  const center = getCenter(listingsWithLocations);

  const listView = (
    <div className="h-screen w-screen flex">
      <div className="w-500 flex-shrink-0 overflow-y-scroll">
        {listings.map((listing) => (
          <InView
            key={listing.url}
            as="div"
            onMouseEnter={() => setHoveredListing(listing.url)}
            onMouseLeave={() => {
              if (hoveredListing === listing.url) {
                setHoveredListing(undefined);
              }
            }}
            onChange={(inView) => listingVisibilityHandler(inView, listing)}
          >
            <Listing
              createdAt={listing.createdAt}
              brokerage={listing.brokerage}
              area={listing.area}
              address={listing.address}
              distance={listing.distance}
              images={listing.images}
              url={listing.url}
              livingArea={listing.livingArea}
              extraArea={listing.extraArea}
              yardArea={listing.yardArea}
              latitude={listing.latitude}
              longitude={listing.longitude}
              image={listing.image}
              booliPrice={listing.booliPrice}
              booliUrl={listing.booliUrl}
            />
          </InView>
        ))}
      </div>
      {screenLarge && (
        <div className="w-full">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAPS_API_KEY,
              mapIds: ['92808c85495a2139'],
            }}
            options={{
              mapId: '92808c85495a2139',
            }}
            defaultCenter={{ lat: center.latitude, lng: center.longitude }}
            defaultZoom={11}
          >
            {listingsWithLocations.map((listing) => {
              return (
                <ListingMarker
                  key={listing.url}
                  visible={visibleListings.includes(listing.url)}
                  active={hoveredListing === listing.url}
                  lat={listing.latitude}
                  lng={listing.longitude}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      )}
    </div>
  );

  return (
    <Router>
      {/* <nav className="flex p-4">
          <Link
            to="/"
            className="bg-white mr-2 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
          >
            Lista
          </Link>
          <Link
            to="/map"
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
          >
            Kartvy
          </Link>
        </nav> */}

      <Switch>
        <Route path="/">{listView}</Route>
      </Switch>
    </Router>
  );
}

export default App;
