import useFetch from 'use-http';

export function useListings() {
  const { loading, data } = useFetch(
    'https://husvakan.oskar.dev/snapshot.json',
    {},
    []
  );

  if (loading) {
    return { loading };
  }

  const listings = data.listings.sort((a, b) => b.createdAt - a.createdAt);

  return { loading: false, listings };
}
