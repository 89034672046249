import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import Lightbox from 'react-image-lightbox';
import Modal from 'react-modal';
import { DateTime } from 'luxon';
import GoogleMapReact from 'google-map-react';

import 'react-image-lightbox/style.css';

import { BROKERAGES } from 'utils/constants';
import ListingMarker from './ListingMarker';

export default React.forwardRef(
  (
    {
      createdAt,
      brokerage,
      address,
      area,
      distance,
      images,
      url,
      livingArea,
      extraArea,
      yardArea,
      latitude,
      longitude,
      image,
      booliPrice,
      booliUrl,
    },
    ref
  ) => {
    const [imagesLightboxOpen, setImagesLightboxOpen] = useState(false);
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
      document.body.style.overflow = locationModalOpen ? 'hidden' : 'initial';
    }, [locationModalOpen]);

    const datetime = DateTime.fromMillis(createdAt)
      .setLocale('sv')
      .toFormat('d LLL HH:mm');

    const getImageUrl = (index) =>
      `https://husvakan.oskar.dev/images/${images[index].id}.webp`;

    const Highlight = tw.span`inline-block px-2 py-1 leading-none bg-gray-100 text-gray-600 rounded-full font-semibold mr-2 tracking-wide text-xs`;

    return (
      <>
        <div ref={ref} className="w-full group p-4">
          <div className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg transition duration-250 ease-in-out overflow-hidden">
            <div className="relative pb-48 overflow-hidden">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src={image}
                alt=""
              />
              <div className="absolute inset-0 bg-black group-hover:bg-opacity-25 bg-opacity-0 transition duration-250 ease-in-out"></div>

              <div className="absolute inset-0 flex items-center justify-center group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto opacity-0 transition duration-250 ease-in-out">
                <a href={url} target="_blank" rel="noreferrer">
                  <i className="fas fa-external-link-alt fa-fw m-4 text-white text-3xl hover:opacity-70"></i>
                </a>
                <button
                  onClick={() => setLocationModalOpen(!locationModalOpen)}
                >
                  <i className="fas fa-map-marker-alt fa-fw m-4 text-white text-3xl hover:opacity-70"></i>
                </button>
                <button
                  onClick={() =>
                    images.length > 0 &&
                    setImagesLightboxOpen(!imagesLightboxOpen)
                  }
                >
                  <i className="fas fa-images fa-fw m-4 text-white text-3xl hover:opacity-70"></i>
                </button>
              </div>
            </div>
            <div className="p-4">
              <Highlight>{BROKERAGES[brokerage]}</Highlight>
              {booliPrice && <Highlight>{booliPrice}</Highlight>}
              <h2 className="mt-4 mb font-bold">
                {address}, {area}
              </h2>
            </div>
            <div className="flex p-4 border-t border-b text-xs text-gray-700">
              <div>
                <span className="flex items-center mb-1">
                  <i className="far fa-clock fa-fw mr-2 text-gray-900"></i>{' '}
                  {datetime}
                </span>
                <span className="flex items-center">
                  <i className="fas fa-car-side fa-fw text-gray-900 mr-2"></i>
                  {distance || '?'} min
                </span>
              </div>
              <div className="ml-4">
                {livingArea && (
                  <span className="flex items-center mb-1">
                    <i className="fas fa-home fa-fw mr-2 text-gray-900"></i>{' '}
                    {livingArea} kvm
                  </span>
                )}
                {extraArea && (
                  <span className="flex items-center mb-1">
                    <i className="fas fa-warehouse fa-fw mr-2 text-gray-900"></i>{' '}
                    {extraArea} kvm
                  </span>
                )}
                {yardArea && (
                  <span className="flex items-center mb-1">
                    <i className="fas fa-tree fa-fw mr-2 text-gray-900"></i>{' '}
                    {yardArea} kvm
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        {imagesLightboxOpen && (
          <Lightbox
            mainSrc={getImageUrl(imageIndex)}
            nextSrc={getImageUrl((imageIndex + 1) % images.length)}
            prevSrc={getImageUrl(
              (imageIndex + images.length - 1) % images.length
            )}
            onCloseRequest={() => setImagesLightboxOpen(false)}
            onMovePrevRequest={() =>
              setImageIndex((imageIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setImageIndex((imageIndex + 1) % images.length)
            }
          />
        )}
        {latitude && longitude && (
          <Modal
            isOpen={locationModalOpen}
            ariaHideApp={false}
            style={{
              content: {
                padding: '0',
              },
            }}
          >
            <button
              className="absolute top-0 lef-0 z-50"
              onClick={() => setLocationModalOpen(false)}
            >
              <i className="fas fa-times fa-fw m-4 text-gray-600 text-4xl hover:opacity-75"></i>
            </button>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
              center={{ lat: latitude, lng: longitude }}
              defaultZoom={11}
            >
              <ListingMarker lat={latitude} lng={longitude} />
            </GoogleMapReact>
          </Modal>
        )}
      </>
    );
  }
);
