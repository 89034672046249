import classNames from 'classnames';

export default ({ visible, active }) => (
  <div className="inline-flex items-center justify-center transform -translate-x-2/4 -translate-y-2/4">
    <i
      className={classNames('m-2', {
        'fas fa-circle fa-xs text-gray-600': !visible,
        'fas fa-map-marker-alt fa-2x text-blue-600 transform -translate-y-2/4':
          visible,
        'fa-3x': active,
      })}
    ></i>
  </div>
);
